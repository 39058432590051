import _ from 'lodash';


export const ValidationUtil = {

	isValidURL(str) {
		let regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
		return !(!regex.test(str));
	},

	// Only allows a-z, A-z, 0-9 and hyphen
	isAlphaNumeric(string) {
		let result = (/^([a-zA-Z\-0-9])*$/.test(string));
		return result;
	},

	isAlphaNumericWithWhiteSpace(string) {
		let result = (/^([a-zA-Z\-0-9\s])*$/.test(string));
		return result;
	},

	isAlphaNumericWithWhiteSpaceAndComma(string) {
		let result = (/^([a-zA-Z\-0-9\s,])*$/.test(string));
		return result;
	},

	isAlphaWithWhiteSpace(string) {
		let result = (/^([a-zA-Z\-.\s])*$/.test(string));
		return result;
	},

	isAlphaWithWhiteSpaceAndComma(string) {
		let result = (/^([a-zA-Z\-,\s])*$/.test(string));
		return result;
	},

	isCharacter(string) {
		let result = (/^([a-zA-Z])*$/.test(string));
		return result;
	},

	isNumeric(string) {
		let result = (/^([0-9])*$/.test(string));
		return result;
	},

	// Param: dateStrng with "mm-dd-yyyy" format
	isDate(string) {
		let result = (/^(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])-(19|20)\d{2}$/.test(string));
		return result;
	},

	isValidPlateNo(plateNo) {
		let result = (/^[a-zA-Z0-9_ ]*$/.test(plateNo));
		return result;
	},

	isValidMobileNumber(mobile) {
		return /^(?:\+?\d{1,3}[ -]?)?\(?\d{3}\)?[ -]?\d{3}[ -]?\d{4}$/.test(mobile);
	},

	isValidTelephoneNumber(telephone) {
		return /^\+?\d{0,3}[-.\s]?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(telephone);
	},

	objectHasField(fieldName, fieldValue, objToCompare) {
		if (objToCompare && !_.isEmpty(objToCompare)) {
			let lowerCaseNames = []

			_.forEach(objToCompare, objectItem => {
				if (objectItem[fieldName]) {
					lowerCaseNames.push(objectItem[fieldName].toLowerCase());
				}
			});

			let lowerCaseFieldValue = fieldValue.toLowerCase();
			if (lowerCaseNames.includes(lowerCaseFieldValue)) {
				return true;
			}
		}
		return false;
	},

	arrayHasValue(optionArr, fieldName, valueToCompare) {
		let found = false;
		if (optionArr && optionArr.length > 0) {
			found = optionArr.some(function (el) {
				if (el.value === null) {
					return false;
				}
				return el.value[fieldName] === valueToCompare;
			});
		}
		return found;
	},

	arrayHasValueDirectField(optionArr, fieldName, valueToCompare) {
		let found = false;
		if (optionArr && optionArr.length > 0) {
			found = optionArr.some(function (el) {
				return el[fieldName] === valueToCompare;
			});
		}
		return found;
	},

	// return empty string for null param
	removeExcessWhiteSpace(stringValue) {
		let trimmedStr = '';
		if (stringValue) {
			trimmedStr = stringValue.trim().replace(/\s{2,}/g, ' ');
		}
		return trimmedStr;
	},

	arrayContains(array, letters) {
		let status = false;
		letters.forEach(letter => {
			array.forEach(item => {
				if (item.includes(letter)) status = letter;
			});
		});
		return status;
	},

	hasDuplicates(array, key) {
		let values = _.map(array, key);
		let uniqValues = _.uniq(values);
		return values.length > uniqValues.length;
	},

	getDuplicates(array, key) {
		let duplicates = [];
		let arrayPerKey = _.groupBy(array, key);

		_.forEach(arrayPerKey, (value, _key) => {
			if (value.length > 1) {
				duplicates.push(_key);
			}
		});

		return duplicates;
	}
}
