// Util
import { DateUtil } from '@/utils/dateutil';

import axios from 'axios';
import config from '@/config/env-constants';


export default {
    baseUrl: 'https://us-central1-ayun-quantity-' + config.currEnv + '.cloudfunctions.net',

    getTransferCompanyRequests(filterBy, view, currUserId) {
        let url = `${this.baseUrl}/getTransferCompanyRequests`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },

    confirmTransferCompanyRequests(transferRequests, currUserId) {
        let url = `${this.baseUrl}/confirmTransferCompanyRequests`;
        return axios.post(url, {
            currUserId: currUserId,
            currTimeStamp: DateUtil.getCurrentTimestamp(),
            transferRequests: JSON.stringify(transferRequests)
        });
    },
}